import React from 'react';
import styled from '@emotion/styled';
import { Flex, Heading, Text } from '@rebass/emotion';
import CmsPage from '../components/Elements/CmsPage';
import { PageProvider } from '../context/PageProvider';
import { Header, Footer } from '../components/PageBlocks';
import Card from '~components/PageBlocks/Card';
import Container from '~components/Elements/Container';

const App = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  padding: 0%;
  max-width: 100vw;
  font-family: ${props => props.theme.fonts.sans};
  /* padding: 0 5vw; */
`;

const Topic = ({ pageContext }) => {
  const articles =
    pageContext.menu.items.find(item => item.page._id === pageContext.topic._id)
      ?.items || [];

  return (
    <CmsPage pageContext={pageContext}>
      <PageProvider page={pageContext}>
        <App>
          <Header data={pageContext.header} />
          <Container>
            <Flex
              py={[20, 30, 40, 100, 100]}
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Heading
                as="h1"
                fontSize="44px"
                fontWeight="bold"
                color="blueBlue160"
                textAlign="center"
                style={{ maxWidth: '815px' }}
              >
                {pageContext.topic.title}
              </Heading>

              <Text
                fontSize="20px"
                lineHeight="28px"
                color="neutralNeutral170"
                textAlign="center"
                mt="32px"
                style={{ maxWidth: '815px' }}
                mb={['20px', '20px', '20px', '20px', 0]}
              >
                {pageContext.topic.description}
              </Text>
              {articles.map((article, index) => {
                return (
                  <React.Fragment key={article._key}>
                    <Card data={article} type={index === 0 ? 1 : 2} />
                    {article.items && article.items.length
                      ? article.items.map(subArticle => (
                          <Card
                            key={subArticle._key}
                            data={subArticle}
                            type={3}
                          />
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
            </Flex>
          </Container>
          <Footer data={pageContext.footer} />
        </App>
      </PageProvider>
    </CmsPage>
  );
};

export default Topic;
